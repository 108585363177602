<template>
  <main>
    <div class="modal modal-lg fade" id="modalCompanys" tabindex="-1" aria-hidden="true" aria-labelledby="modalTitle" data-bs-backdrop="status">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalTitle">
              {{ viewDataArray.academyDivision }}
            </h5>
            <button type="button" id="CloseModal" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <!-- <p>Sector Empresarial: {{ viewDataArray.careersUniversity }}</p>
            <p>Responsable: {{ viewDataArray.responsableUniversity }}</p>
            <p>Teléfono: {{ viewDataArray.phoneUniversity }}</p>
            <hr /> -->
            <p style="font-size: 15px; text-transform: uppercase; text-align: center; margin-bottom: 4px; margin-top: 4px">Agrega las extensiones y links de la universidad</p>
            <form @submit.prevent="savedData()" class="center" style="display: flex; flex-direction: column; gap: 5px; margin-top: 5%">
              <div class="col-md-8">
                <p style="font-weight: bolder">Genera un usuario para la universidad</p>
                <div class="form-floating mb-3">
                  <input type="text" v-model="slug" class="form-control" id="nameComercialInput" placeholder="Nombre Completo del Contacto" />
                  <label for="floatingInput">Ingresa un identificador. </label>
                </div>
              </div>
              <div class="col-md-8">
                <p style="font-weight: bolder">Ingresa enlace para eventos</p>
                <div class="form-floating mb-3">
                  <input type="text" v-model="linkEvents" class="form-control" id="nameComercialInput" placeholder="Nombre Completo del Contacto" />
                  <label for="floatingInput">Ingresa un link externo de eventos. </label>
                </div>
              </div>
              <div class="col-md-8">
                <p style="font-weight: bolder">Extensión o link de Oferta Educativa</p>
                <div class="form-floating mb-3">
                  <input type="text" v-model="linkOffer" class="form-control" id="nameComercialInput" placeholder="Nombre Completo del Contacto" />
                  <label for="floatingInput">Ingresa link para oferta educativa. </label>
                </div>
              </div>
              <div class="col-md-8">
                <p style="font-weight: bolder">Extensión o link de Tienda</p>
                <div class="form-floating mb-3">
                  <input type="text" v-model="linkStore" class="form-control" id="nameComercialInput" placeholder="Nombre Completo del Contacto" />
                  <label for="floatingInput">Ingresa un link de tienda virtual. </label>
                </div>
              </div>
              <div class="col-4">
                <button class="btn-public btn btn-outline-primary" :disabled="response.loading === true">
                  <span>Guardar</span>
                  <div class="spinner-border spinner-border-sm float-end" role="status" v-show="response.loading === true">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="modal modal-lg fade" id="modalUniversities" tabindex="-1" aria-hidden="true" aria-labelledby="modalTitle" data-bs-backdrop="status">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalTitle">
              {{ viewDataArray.academyDivision }}
            </h5>
            <button type="button" id="CloseModalU" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <p><b>Nombre de la Universidad:</b> {{ viewDataArray.nameUniversity }}</p>
            <p><b>División:</b> {{ viewDataArray.academyDivision }}</p>
            <p><b>Campus:</b> {{ viewDataArray.campus }}</p>
            <p><b>Sector Estudiantil:</b> {{ viewDataArray.careersUniversity }}</p>
            <p><b>Entidad Federativa:</b> {{ viewDataArray.federalentityUniversity }}</p>
            <p><b>Sitio Web:</b> {{ viewDataArray.sitioWeb }}</p>
            <p><b>Responsable:</b> {{ viewDataArray.responsableUniversity }}</p>
            <p><b>Puesto del Responsable:</b> {{ viewDataArray.positionUniversity }}</p>
            <p><b>Teléfono:</b> {{ viewDataArray.phoneUniversity }}</p>
            <p><b>Identificador:</b> mundouniversitario.com/{{ viewDataArray.slug }}</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-success" data-bs-dismiss="modal" @click="aceptUniversities()">
              Aceptar Universidad
            </button>
            <!-- <button type="button" class="btn btn-danger" data-bs-dismiss="modal" @click="deleteCompany()">
              Rechazar Empresa
            </button> -->
          </div>
        </div>
      </div>
    </div>
    <div class="card-title mt-5">
      <h3 class="card-label text-dark">Universidades</h3>
    </div>
    <table class="table align-middle table-responsive table-hover caption-top">
      <thead class="placeholder-glow">
        <tr align="center">
          <th scope="col">Nombre Universidad</th>
          <th scope="col">Abreviación</th>
          <th scope="col">División académica</th>
          <th scope="col">Correo de Contacto</th>
          <th scope="col">Fecha de Registro</th>
          <th scope="col">Estatus de Aceptacion</th>
          <th scope="col">Bolsa de Empleo</th>
          <th scope="col">Oferta educativa</th>
          <th scope="col">Tienda</th>
          <th scope="col">Eventos</th>
          <th scope="col">Opciones</th>
        </tr>
      </thead>
      <tbody v-for="(school, i) in listCompany" :key="i + 1">
        <tr align="center">
          <td scope="row" style="text-transform: uppercase">{{ school.nameUniversity }}</td>
          <td>{{ school.abbreviationUniversity }}</td>
          <td>{{ school.academyDivision }}</td>
          <td>{{ school.emailUniversity }}</td>
          <td>
            <span v-if="school.created_at">
              {{ moment(school.created_at).tz(`${userTimeZone}`).format("DD/MM/YYYY") }}
            </span>
            <span v-else-if="school.createdAt">
              {{ moment(school.createdAt).tz(`${userTimeZone}`).format("DD/MM/YYYY") }}
            </span>
          </td>
          <td>
            <p class="badge" :class="{ 'bg-danger': !school.status, 'bg-success': school.status, }">
              {{ school.status ? "Aceptado" : "Pendiente" }}
            </p>
          </td>
          <td>
            <div class="select-status-account" style="display: flex; justify-content: center; align-items: center">
              <span>
                <div class="form-check form-switch">
                  <input class="form-check-input" type="checkbox" @change="updateMode('bag', school)" v-model="school.universityBag" role="switch" id="flexSwitchCheckDefault" />
                </div>
              </span>
            </div>
          </td>
          <td>
            <div class="select-status-account" style="display: flex; justify-content: center; align-items: center">
              <span>
                <div class="form-check form-switch">
                  <input class="form-check-input" @change="updateMode('offer', school)" type="checkbox" v-model="school.educationalOffer" role="switch" id="flexSwitchCheckDefault" />
                </div>
              </span>
            </div>
          </td>
          <td>
            <div class="select-status-account" style="display: flex; justify-content: center; align-items: center">
              <span>
                <div class="form-check form-switch">
                  <input class="form-check-input" @change="updateMode('store', school)" type="checkbox" v-model="school.universityStore" role="switch" id="flexSwitchCheckDefault" />
                </div>
              </span>
            </div>
          </td>
          <td>
            <div class="select-status-account" style="display: flex; justify-content: center; align-items: center">
              <span>
                <div class="form-check form-switch">
                  <input class="form-check-input" @change="updateMode('events', school)" type="checkbox" v-model="school.universityEvents" role="switch" id="flexSwitchCheckDefault" />
                </div>
              </span>
            </div>
          </td>
          <td style="display: flex; flex-direction: row; justify-content: center; align-content: center;">
            <button type="button" class="btn btn-icon btn-primary btn-hover-primary btn-sm mx-2 my-2" @click="viewData(school)" title="Agregar Links">
              <box-icon type="solid" color="white" name="show"></box-icon>
            </button>
            <button type="button" class="btn btn-icon btn-primary btn-hover-primary btn-sm mx-2 my-2" @click="viewDataUniversity(school)" title="Ver Datos de Universidad">
              <box-icon type="solid" color="white" name="edit"></box-icon>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <!-- <nav aria-label="Page navigation example">
          <ul class="pagination">
            <li class="page-item" v-on:click="getPreviousPage()">
              <a class="page-link" href="#">Anterior</a>
            </li>
            <li
              v-for="(page, i) in totalPages()"
              :key="i + 1"
              v-on:click="getDataPages(page)"
              v-bind:class="isActive(page)"
              class="page-item"
            >
              <a class="page-link" href="#">{{ page }}</a>
            </li>
            <li class="page-item" v-on:click="getNextPage()">
              <a class="page-link" href="#">Siguiente</a>
            </li>
          </ul>
        </nav> -->
  </main>
</template>

<script>
import { AdminService } from "@/services";
import moment from "moment-timezone";

export default {
  data: () => ({
    table: null,
    elementPage: 25,
    dataPage: [],
    listCompany: [],
    modalCompany: null,
    modalUniversity: null,
    id: null,
    idUser: null,
    status: null,
    actual: 1,
    viewDataArray: {},
    moment: moment,
    response: {
      loading: false,
    },
    slug: "",
    linkEvents: "",
    linkOffer: "",
    linkStore: "",
  }),
  mounted() {
    this.getDataPages(1);
    this.getUniversidades();
    setTimeout(() => {
      this.initialModal();
      this.initialModalU();
    }, 1000);
  },
  methods: {
    initialModal() {
      this.modalCompany = new window.bootstrap.Modal(document.getElementById("modalCompanys"), {
        keyboard: false,
      });
    },
    initialModalU(){
      this.modalUniversity = new window.bootstrap.Modal(document.getElementById("modalUniversities"), {
        keyboard: false,
      });
    },  
    totalPages() {
      return Math.ceil(this.listCompany.length / this.elementPage);
    },

    async getUniversidades() {
      var resp = await AdminService.getUniversidadesAdmin({ search: this.search });
      var { data } = resp.data;
      console.log(data);
      this.listCompany = data.reverse();
    },
    viewData(company) {
      this.viewDataArray = company;
      this.modalCompany.show();
      this.selectedItem = company;
      this.id = company._id;
      this.idUser = company._id_user;
    },
    viewDataUniversity(company) {
      this.viewDataArray = company;
      this.modalUniversity.show();
      this.selectedItem = company;
      this.id = company._id;
      this.idUser = company._id_user;
    },
    async updateMode(mode, item) {
      let data = {};
      if (mode === "store") {
        data = {
          mode: mode,
          id: item._id,
          universityStore: item.universityStore,
        };
      } else if (mode === "bag") {
        data = {
          mode: mode,
          id: item._id,
          universityBag: item.universityBag,
        };
      } else if (mode === "offer") {
        data = {
          mode: mode,
          id: item._id,
          educationalOffer: item.educationalOffer,
        };
      } else {
        data = {
          mode: mode,
          id: item._id,
          universityEvents: item.universityEvents,
        };
      }
      await AdminService.updateModeUniversity(data)
        .then((res) => {
          this.$swal({
            position: "top-center",
            icon: "success",
            text: res.data.message,
            showConfirmButton: false,
            timer: 2500,
          });
          this.getUniversidades();
        })
        .catch((e) => {
          this.$swal({
            position: "top-center",
            icon: "error",
            title: e.response.data.message,
            showConfirmButton: false,
            timer: 2500,
          });
        });
    },
    clearInput(){
        this.slug = "";
        this.linkEvents = "";
        this.linkOffer = "";
        this.linkStore = "";
    },
    getDataPages(numberPage) {
      this.actual = numberPage;
      this.dataPage = [];
      let start = numberPage * this.elementPage - this.elementPage;
      let end = numberPage * this.elementPage;
      this.dataPage = this.listCompany.slice(start, end);
    },
    getPreviousPage() {
      if (this.actual > 1) {
        this.actual--;
      }
      this.getDataPages(this.actual);
    },
    getNextPage() {
      if (this.actual < this.totalPages()) {
        this.actual++;
      }
      this.getDataPages(this.actual);
    },
    isActive(numberPage) {
      return numberPage === this.actual ? "active" : "";
    },

    async savedData() {
      let data = {
        id: this.id,
      };
      if (this.slug) {
        data.slug = this.slug;
      }
      if (this.linkEvents) {
        data.linkEvents = this.linkEvents;
      }
      if (this.linkOffer) {
        data.linkOffer = this.linkOffer;
      }
      if (this.linkStore) {
        data.linkStore = this.linkStore;
      }
      await AdminService.updateUniversityLinks(data)
        .then((res) => {
          this.$swal({
            position: "top-center",
            icon: "success",
            text: res.data.message,
            showConfirmButton: false,
            timer: 2500,
          });
          this.clearInput();
          this.modalCompany.hide();
          this.getUniversidades();
        })
        .catch((e) => {
          this.$swal({
            position: "top-center",
            icon: "error",
            title: e.response.data.message,
            showConfirmButton: false,
            timer: 2500,
          });
        });
    },
    aceptUniversities() {
      let data = { id: this.id, idUser: this.idUser, status: this.status };
      AdminService.aceptUniversity(data).then((res) => {
        this.$swal({
          position: "top-center",
          icon: "success",
          title: res.data.message,
          showConfirmButton: false,
          timer: 2500,
        });
        this.getUniversidades();
      })
        .catch((e) => {
          this.$swal({
            position: "top-center",
            icon: "error",
            title: e.response.data.message,
            showConfirmButton: false,
            timer: 2500,
          });
        });
    },
  },
};
</script>
